<template>
  <div class="users-list">
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :footer-props="{
        itemsPerPageText: $t('GlobalUI.RowsPerPage'),
      }"
      :items="users"
      :items-per-page="10"
      :loading="isUsersLoading"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      show-expand
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.firstName }} {{ item.lastName }}
      </template>
      <template v-slot:[`item.role`]="{ value }">
        {{ $t(`Users.Role${value.label}`) }}
      </template>
      <template v-slot:[`item.netStatus`]="{ value, item }">
        <span v-if="item.id === user.id">{{ $t("Users.Me") }}</span>
        <template v-else>
          <v-icon class="mr-2" size="14" :color="userNetStatusObject(value).color">
            mdi-circle
          </v-icon>
          <span class="overline" :class="`${userNetStatusObject(value).color}--text`">
            {{ value }}
          </span>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template v-for="(action, idx) in actions(item)">
          <v-tooltip v-if="action.isCan && action.helper" :key="idx" max-width="300" top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :class="action.class"
                v-bind="attrs"
                v-on="on"
                :to="action.to"
                @click.stop.prevent="action.action(item)"
                icon
              >
                <v-icon> {{ action.icon }} </v-icon>
              </v-btn>
            </template>
            <span> {{ action.helper }} </span>
          </v-tooltip>
          <v-btn v-else :to="action.to" @click.stop.prevent="action.action(item)" :key="idx" icon>
            <v-icon> {{ action.icon }} </v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <table class="user-additional-info">
            <tr>
              <th class="user-additional-info__avatar">{{ $t("Users.Avatar") }}</th>
              <th>E-Mail</th>
            </tr>
            <tr>
              <td>
                <v-img-extend
                  class="rounded-lg"
                  :style="{ width: '60px' }"
                  :img="item.avatar"
                  fallback-size="sm"
                  size="sm"
                />
              </td>
              <td>
                <a :href="`mailto:${item.email}`">{{ item.email }}</a>
              </td>
            </tr>
          </table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import usersService from "../../services/users.service";

import { confirm } from "../../helpers/dialogs";

import { USER_NET_STATUS } from "../../config";

export default {
  name: "VUsersList",

  data() {
    return {
      USER_NET_STATUS,

      users: [],
      isUsersLoading: false,
    };
  },

  sockets: {
    userStatusChange: function ({ netStatus, id }) {
      if (this.user.id === id) return;
      this.users = this.users.map((user) => (user.id !== id ? user : { ...user, netStatus }));
    },
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    headers() {
      return [
        {
          text: this.$t("Users.FullName"),
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: this.$t("Users.UserName"), value: "username" },
        { text: this.$t("Users.Role"), value: "role" },
        { text: this.$t("Users.NetStatus"), value: "netStatus" },
        { text: this.$t("Users.Actions"), value: "actions", sortable: false, width: 160 },
        { text: "", value: "data-table-expand" },
      ];
    },
    userNetStatusObject() {
      return (status) => this.USER_NET_STATUS.find((item) => item.key === status);
    },
    actions() {
      return ({ id: userId }) => {
        const isCurrentUser = this.user.id === userId;
        return [
          {
            to: { name: "user.privileges", params: { userId } },
            icon: "mdi-account-lock",
            helper: this.$t("Users.ChangePermissions"),
            isCan: true,
            class: { "mr-2": true },
            action: () => {},
          },
          {
            to: { name: "user.edit", params: { userId } },
            icon: "mdi-pencil",
            helper: this.$t("Users.EditUserProfile"),
            isCan: this.$mcan(["*", "edit"], "supplier:users"),
            class: { "mr-2": true },
            action: () => {},
          },
          {
            to: undefined,
            icon: "mdi-delete",
            helper: isCurrentUser ? this.$t("Users.YouCanNotDeleteYourself") : null,
            isCan: true,
            class: { "v-btn--disabled": isCurrentUser },
            action: !isCurrentUser ? this.handleClickDeleteUser : () => {},
          },
        ];
      };
    },
  },

  async created() {
    this.isUsersLoading = true;

    this.users = await usersService.getAll();

    this.isUsersLoading = false;
  },

  methods: {
    async handleClickDeleteUser({ id }) {
      const result = await this.$dialog.warning(confirm());
      if (!result) return;

      await usersService.deleteById(id);
      this.users = this.users.filter((user) => user.id !== id);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  &.v-btn--disabled {
    cursor: not-allowed;
    pointer-events: auto;
    &:hover:before {
      opacity: 0;
    }
  }
}

.user-additional-info {
  width: 100%;
  margin: 15px 0;
  border-collapse: collapse;
  border: none;

  th {
    font-size: 0.75rem;
    text-align: left;
  }

  th,
  td {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &__avatar {
    width: 80px;
  }
}
</style>