var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-list"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"footer-props":{
      itemsPerPageText: _vm.$t('GlobalUI.RowsPerPage'),
    },"items":_vm.users,"items-per-page":10,"loading":_vm.isUsersLoading,"show-expand":""},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.role",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("Users.Role" + (value.label))))+" ")]}},{key:"item.netStatus",fn:function(ref){
    var value = ref.value;
    var item = ref.item;
return [(item.id === _vm.user.id)?_c('span',[_vm._v(_vm._s(_vm.$t("Users.Me")))]):[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"14","color":_vm.userNetStatusObject(value).color}},[_vm._v(" mdi-circle ")]),_c('span',{staticClass:"overline",class:((_vm.userNetStatusObject(value).color) + "--text")},[_vm._v(" "+_vm._s(value)+" ")])]]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_vm._l((_vm.actions(item)),function(action,idx){return [(action.isCan && action.helper)?_c('v-tooltip',{key:idx,attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:action.class,attrs:{"to":action.to,"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return action.action(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(action.helper)+" ")])]):_c('v-btn',{key:idx,attrs:{"to":action.to,"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return action.action(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"user-additional-info"},[_c('tr',[_c('th',{staticClass:"user-additional-info__avatar"},[_vm._v(_vm._s(_vm.$t("Users.Avatar")))]),_c('th',[_vm._v("E-Mail")])]),_c('tr',[_c('td',[_c('v-img-extend',{staticClass:"rounded-lg",style:({ width: '60px' }),attrs:{"img":item.avatar,"fallback-size":"sm","size":"sm"}})],1),_c('td',[_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])])])])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }