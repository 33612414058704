<template>
  <div class="users-list-page">
    <div class="users-list-header">
      <p class="users-list-description">
        {{ $t("Users.ManagmentListSubTitle") }}
      </p>
      <v-add-btn :to="{ name: 'user.create' }" />
    </div>
    <v-users-list />
  </div>
</template>

<script>
import VUsersList from "../../components/users/VUsersList";

export default {
  name: "UsersList",

  layout: "default",

  components: {
    VUsersList,
  },

  metaInfo() {
    return { title: this.$t("Navigation.Users") };
  },
};
</script>

<style lang="scss" scoped>
.users-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.users-list-description {
  margin: 0;
  max-width: 800px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .users-list-header {
    flex-direction: column;
    align-items: flex-end;
  }

  .users-list-description {
    margin-bottom: 30px;
  }
}
</style>
